<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="/"
        target="_blank"
      ><myegrn-logo /></b-link>
      <span class="d-none d-sm-inline-block">, {{ t('All rights Reserved') }}</span>
    </span>

    <span class="float-md-right d-none d-md-block">{{ t('Made with') }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import MyegrnLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BLink,
    MyegrnLogo,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
}
</script>
