export default [
  {
    header: 'Возможности',
  },
  {
    title: 'Архив',
    icon: 'ServerIcon',
    route: { name: 'apps-excerpt-search-folder', params: { label: 'all', folder: 'all' } },
  },
  {
    title: 'Заказы',
    icon: 'ShoppingBagIcon',
    route: { name: 'apps-order-list-folder', params: { label: '90days', folder: 'all' } },
  },
  {
    title: 'Ваши файлы',
    icon: 'LayersIcon',
    route: 'apps-excerpt-list',
  },
  {
    title: 'Загрузить',
    icon: 'UploadIcon',
    route: { name: 'apps-excerpt-add' },
  },
  {
    title: 'Баллы',
    icon: 'WindIcon',
    route: { name: 'apps-point-list' },
  },
  {
    title: 'Помощь',
    icon: 'HelpCircleIcon',
    route: { name: 'pages-faq' },
  },
  {
    title: 'Партнёрам',
    icon: 'UserPlusIcon',
    route: { name: 'pages-partners' },
  },
  {
    title: 'Checkout',
    icon: 'ShoppingCartIcon',
    route: 'apps-e-commerce-checkout',
  },
  {
    title: 'Пополнить баланс',
    style: 'background: rgba(255, 0, 240, 0.2)',
    icon: 'PlusIcon',
    route: { name: 'app-points-add' },
  },
  {
    title: 'ЕГРН Конвертер',
    icon: 'UploadIcon',
    route: { name: 'apps-excerpt-converter' },
  },
]
