<template>
  <b-nav-item-dropdown
    ref="headerdropdown"
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Файлы в корзине
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ cartItemsCount }} шт.
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="cartItemsCount"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="(item, index) in cartItems"
        :key="item.name"
      >
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ (index + 1) + ". " + item.kad }}
            </b-link>
          </h6>
          <small class="cart-item-by">{{ new Date(item.dateCreate).toLocaleDateString() }} - {{ item.fileType }}</small>
        </div>

        <h5 class="cart-item-price">
          150
          <feather-icon
            icon="WindIcon"
            class="mx-auto"
            color="red"
          />
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="cartItemsCount"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Всего:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ totalAmount }}
          <feather-icon
            icon="WindIcon"
            class="mx-auto"
            color="red"
          />
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'apps-e-commerce-checkout' }"
        @click="$refs.headerdropdown.hide(true)"
      >
        Перейти в корзину
      </b-button>
    </li>

    <p
      v-if="!cartItemsCount"
      class="m-0 p-1 text-center"
    >
      Нет файлов в корзине
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = ref({
      maxScrollbarLength: 60,
      wheelPropagation: false,
    })
    const removeItemFromCart = idFile => {
      store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', idFile)
    }

    const totalAmount = computed(() => {
      let total = 0
      store.getters['app-ecommerce/cartItems'].forEach(() => { total += 150 })
      return total
    })

    const cartItemsCount = computed(() => store.getters['app-ecommerce/cartItemCnt'])
    const cartItems = computed(() => store.getters['app-ecommerce/cartItems'])

    return {
      perfectScrollbarSettings,
      removeItemFromCart,
      totalAmount,
      cartItemsCount,
      cartItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
