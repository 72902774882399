<template>
  <big>
    <b-link
      class="mr-1"
      @click="LoadingBalance"
    >
      <feather-icon
        v-if="!isLoading"
        icon="RefreshCwIcon"
        size="21"
      />
      <b-spinner v-if="isLoading" style="width: 1.5rem; height: 1.5rem;" />
    </b-link>
    Ваш баланс:
    {{ balance }} <feather-icon
      icon="WindIcon"
      color="red"
      size="21"
    />
  </big>
</template>

<script>
import {
  BLink, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BSpinner,
  },
  setup() {
    const isLoading = ref(false)
    const balance = computed(() => store.getters['app-ecommerce/balance'])

    const LoadingBalance = () => {
      isLoading.value = true
      store.dispatch('app-ecommerce/fetchUserBalance')
        .then(() => {
          isLoading.value = false
        })
    }

    return {
      LoadingBalance,
      balance,
      isLoading,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
