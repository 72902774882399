<template>
  <b-nav-item-dropdown
    ref="headerdropdown"
    class="dropdown-cart mr-15"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="selectFilesCnt"
        class="text-body"
        icon="FileIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Файлов выбрано
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ selectFilesCnt ? selectFilesCnt : "0" }} шт.
        </b-badge>
      </div>
    </li>

    <!-- Cart Footer -->
    <li
      v-if="selectFilesCnt"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="isDownload"
        @click="XMLMultyDownload(selectFiles); $refs.headerdropdown.hide(true)"
      >
        Скачать XML
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="true"
      >
        Выгрузить ограничения/обременения в Excel
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="true"
      >
        Выгрузить правообладателей в Excel
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'
import useDownloads from '@/views/apps/excerpt/useDownloads'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      XMLMultyDownload,
    } = useDownloads()

    const perfectScrollbarSettings = ref({
      maxScrollbarLength: 60,
      wheelPropagation: false,
    })
    const removeItemFromCart = idFile => {
      store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', idFile)
    }

    const totalAmount = computed(() => {
      let total = 0
      store.getters['app-ecommerce/cartItems'].forEach(() => { total += 150 })
      return total
    })

    const selectFilesCnt = computed(() => store.getters['app-ecommerce/selectFilesCnt'])
    const selectFiles = computed(() => store.getters['app-ecommerce/selectFiles'])
    const isDownload = computed(() => store.getters['app-ecommerce/isDownload'])

    return {
      perfectScrollbarSettings,
      removeItemFromCart,
      totalAmount,
      selectFilesCnt,
      selectFiles,
      isDownload,

      XMLMultyDownload,
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
