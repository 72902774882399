/* eslint-disable object-curly-newline */
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { endpoint } from '@/@core/endpoint'
import excerptStoreModule from './excerptStoreModule'

export default function useDownloads() {
  const EXCERPT_APP_STORE_MODULE_NAME = 'app-excerpt'

  // Register module
  if (!store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.registerModule(EXCERPT_APP_STORE_MODULE_NAME, excerptStoreModule)

  // Use toast
  const toast = useToast()
  const types = [9, 10, 12, 13, 22, 32, 35, 51, 104, 107, 108, 109]

  const download = (fileUrl, fileName) => {
    const a = document.createElement('a')
    a.href = fileUrl
    a.setAttribute('download', fileName)
    a.click()
    a.remove()
  }

  const createObjectURL = object => ((window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object))
  const revokeObjectURL = object => ((window.URL) ? window.URL.revokeObjectURL(object) : window.webkitURL.revokeObjectURL(object))
  const XMLSingleDownload = (id, fileTypeId) => {
    // console.log(id, fileTypeId)
    if (!fileTypeId || !types.includes(fileTypeId)) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Ошибка скачивания XML',
          icon: 'LoginCoffeeIcon',
          variant: 'danger',
          text: 'Для этого файла выгрука не доступна',
        },
      })
      return
    }
    store.dispatch('app-excerpt/fetchXmlExcerpt', { id })
      .then(response => {
        // console.log(response)
        const blob = new Blob([response.data], { type: 'text/xml' })
        // create file link in browser's memory
        const href = createObjectURL(blob)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `file${id}.xml`) // or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        revokeObjectURL(href)
      })
      .catch(() => {
        // console.log(error)
      })
  }

  const XMLMultyDownload = idds => {
    store.commit('app-ecommerce/SET_IS_DOWNLOAD', true)
    store.dispatch('app-excerpt/fetchMultyXmlExcerpt', { idds })
      .then(response => {
        // console.log(response)
        const sp = response.data.Data.zipPath.split('/')
        download(`${endpoint()}${response.data.Data.zipPath}`, sp[sp.length - 1])
        store.commit('app-ecommerce/SET_IS_DOWNLOAD', false)
      })
      .catch(() => {
        // console.log(error)
        store.commit('app-ecommerce/SET_IS_DOWNLOAD', false)
      })
  }

  return {
    XMLSingleDownload,
    XMLMultyDownload,
  }
}
